/**
 * Custom fetch encapsulation. Marks all exceptions with Error.isFetchError = true flag
 * that can be used for logging purposes.
 */
const customFetch = (resource: RequestInfo, init?: RequestInit) => {
  return fetch(resource, init).catch(err => {
    err.isFetchError = true
    throw err
  })
}

export default customFetch
